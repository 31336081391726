/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Scanner from "../../Components/shared/scannerlogin";
import "./Login.css";
import logo from "../../assets/logo.png";
import axios from "axios";
import { validateClientCode } from '../shared/helpers';
import Warning from '../WarningMessage/Warning';
import { useNavigate } from 'react-router-dom';

const initialValues = {
    userName: '',
    password: ''
};

const Login = () => {
    const [scanCode, setScanCode] = React.useState("")
    const [isScanner, setScanner] = React.useState(false);
    const [isClientValidated, setIsClientValidated] = React.useState(false);
    const [formValues, setFormValues] = React.useState(initialValues);
    const navigate = useNavigate();
    const [loggedInUserInfo, setLoggedInUserInfo] = React.useState({
        clientKey: '',
        returnUrl: '',
        scanOption: '',
        loginURL: '',
        redirectUrl: '',
        externalAuthKey: null
    });

    const [showSpinner, setShowSpinner] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [showInvalidQR, setShowInvalidQR] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState("");
    const [clientDetails, setClientDetails] = React.useState({});
    const [displayWarning, setDisplayWarning] = React.useState(false);
    let urlSerachParams = window.location.href.split("?")[1];
    const queryParams = new URLSearchParams(urlSerachParams);
    let scanner = queryParams.get("scan");

    React.useEffect(() => {
        let loginData = validateClientCode();
        setLoggedInUserInfo(loginData)
        if (loginData.clientKey) {
            //Prepare payload to check the client key validation
            let payload = JSON.stringify({
                "clientKey": loginData.clientKey,
                "grant_type": "client_code"
            })

            //Make request to validate the client key
            axios.post(`${process.env.REACT_APP_LOGIN_API_URL_East}`, payload, {
                //Include the function key into headers.
                headers: {
                    "x-functions-key": `${process.env.REACT_APP_FUNCTION_KEY}`
                }
            }).then((res) => {
                //Check the response to find the validation status
                let ClientCode = res.data?.clientCode;
                if (ClientCode !== "Operation returned an invalid status code 'NotFound'") {
                    setClientDetails(res.data);
                    //Navigate to login page.
                    setIsClientValidated(true);
                } else {
                    //If API returns the invalid response navigate to warning page.
                    //navigate('/warning');
                    setDisplayWarning(true);
                }
            }).catch((error) => {
                //If API returns the invalid response navigate to warning page.
                //navigate('/warning');
                setDisplayWarning(true);
            });
        } else {
            console.log("Error");
            //navigate('/warning');
            setDisplayWarning(true);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setShowErrorMessage("");
        setShowError(false);
        setFormValues({...formValues,isScanner:isScanner,grand_type: 'password'});
    }, [])


    const getScanner = (code) => {
        setScanCode(code);
        if (code !== null) {
            setScanner(true);
            setFormValues({...formValues,isScanner:true})
            setShowInvalidQR(false);
        } else {
            setScanner(false);
            setFormValues({...formValues,isScanner:false})
            setShowInvalidQR(true);
        }
    }

    const onCredentialsChange = () => {
        setScanCode("");
        setShowInvalidQR(false);
        setShowError(false);
    }

    const SignupSchema = Yup.object().shape({
        isScanner: Yup.boolean(),
        userName: Yup.string().when('isScanner', {
            is: false,
            then: Yup.string().email('Invalid email').required('Please enter email'),
            otherwise: Yup.string()
        }),
        password: Yup.string().when('isScanner', {
            is: false,
            then: Yup.string().required('Please enter password'),
            otherwise: Yup.string()
        })
    });
    const tryLogin = (values) => {
        setShowSpinner(true);
        setShowError(false);
        setShowInvalidQR(false);
        let extAuthKey = loggedInUserInfo.externalAuthKey !== null ? loggedInUserInfo.externalAuthKey : null;
        let granttype = loggedInUserInfo.externalAuthKey !== null ? 'token' : 'password';
        let payload = JSON.stringify({
            'userName': values.userName,
            'password': values.password,
            "clientKey": loggedInUserInfo.clientKey,
            "grant_type": granttype,
            "externalAuthKey": extAuthKey
        })
        axios.post(`${process.env.REACT_APP_LOGIN_API_URL_East}`, payload, {
            headers: {
                "x-functions-key": `${process.env.REACT_APP_FUNCTION_KEY}`
            }
        }).then((res) => {
            console.log("hello");
            handleLoginResponse(res?.data);
        }).catch( (error) =>{
            console.log("hi");
            console.log(error);
            handleLoginResponse(error?.response?.data);
        })
    }

    const handleLoginResponse = (data) => {
        setShowSpinner(false);
        setShowError(false);
        if (data.statusCode === 200) {
            let clientCode = data.securityCode;
            let zone =data.zone;
            localStorage.setItem('LoginStatus', 'Success');
            navigateTorunwaymain(clientCode, zone, loggedInUserInfo,  data?.tokenDetils?.access_token);
        } else {      
            if (loggedInUserInfo?.externalAuthKey !== null && data?.message ==='Invalid access token'){
                localStorage.setItem('LoginStatus', 'Invalid External Access Key');
                navigateToTokenDetailsPage();
            }else{
                localStorage.setItem('LoginStatus', 'Fail');
                setShowInvalidQR(false);
                const messageData = JSON.parse(data?.message);
                setShowErrorMessage("*Invalid details!");
                setShowError(true);
                console.log(messageData?.error?.message);
            }              
        }
    }

    const handleKeyPressLogin = (e) => {
        if (e.key === "Tab") {
            e.preventDefault();
            let login = e.target.value;
            let usernamePassword = login.split(";");
            let loginCredentials = {};
            loginCredentials.userName = usernamePassword[0];
            loginCredentials.password = usernamePassword[1];
            console.log(loginCredentials);
            tryLogin(loginCredentials);
        }
    }

    function navigateToTokenDetailsPage(){
        let route = `/token`;
        navigate(route);
    }

    function navigateTorunwaymain(clientCode, zone, loginInfo, token) {
        if (loginInfo.externalAuthKey !== null) {
            let route = `/token?accesstoken=${token}`;
            navigate(route);
        } else {
            let baseURL = '';
            if (loginInfo.redirectUrl !== '') {
                baseURL = `${loginInfo.redirectUrl}?securitycode=${clientCode}&zone=${zone}&loginURL=${loginInfo.loginURL}`;
            } else {
                baseURL = `${process.env.REACT_APP_RUNWAY_MAIN}/#/clientcode?securitycode=${clientCode}&zone=${zone}&loginURL=${loginInfo.loginURL}&addtionalroutes=${loginInfo.returnUrl?.replace('/#/', '/')}&prefix=${loginInfo.clientKey}`;
            }
            window.location.replace(baseURL);
        }


    }

    return (
        <section className="main vh-100">
            {
                displayWarning ?
                    <Warning></Warning>
                    :
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div style={{ color: '#98989e' }}>
                            {
                                isClientValidated ?
                                    <div className="card shadow" style={{ backgroundColor: '#24252f',color: '#98989e' }}>
                                        <div className=" text-center">
                                            <img src={logo} alt="logo" width="250" />
                                        </div>
                                        <hr />
                                        <h5 className="text-center m-0">Runway Login</h5>
                                        <label className="text-center clientDataWarp p-1 m-0">
                                            <label><span> Company: &nbsp;{clientDetails?.companyName}</span> &nbsp; &nbsp; &nbsp; </label>
                                            <label><span> Account: &nbsp;{loggedInUserInfo?.clientKey.split('-')[0]}</span> &nbsp; &nbsp; &nbsp;</label>
                                            <label><span> Database: &nbsp;{clientDetails?.database}</span> </label>
                                        </label>
                                        <hr />

                                        {showError && <span className='text-center text-danger'>{showErrorMessage}</span>}
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={formValues}
                                            validationSchema={SignupSchema}
                                            onSubmit={(values) => {
                                                let loginDetails = {};
                                                if (scanCode !== "") {
                                                    if (scanCode.includes(";")) {
                                                        let newScan = scanCode.split(';')
                                                        loginDetails.userName = newScan[0];
                                                        loginDetails.password = newScan[1];
                                                        // values.userName = newScan[0]
                                                        // values.password = newScan[1]
                                                    } else {
                                                        setShowInvalidQR(true);
                                                        setShowError(false);
                                                        return;
                                                    }
                                                } else {
                                                    if (values.userName === "") {
                                                        setShowErrorMessage("Please enter username");
                                                        setShowError(true);
                                                        return;
                                                    } else {
                                                        loginDetails.userName = values.userName;
                                                    }

                                                    if (values.password === "") {
                                                        setShowErrorMessage("Please enter password");
                                                        setShowError(true);
                                                        return;
                                                    } else {
                                                        loginDetails.password = values.password;
                                                    }
                                                    // actions.resetForm();
                                                }

                                                delete values.isScanner;
                                                console.log(loginDetails);
                                                tryLogin(loginDetails);
                                                setScanCode('')
                                            }}>
                                            {({ errors, touched, setFieldValue }) => (
                                                <Form className="px-5">
                                                    {
                                                        scanner === "true" &&
                                                        <>
                                                            <Scanner name="scanner" handleChange={getScanner} handleKeyPressLogin={handleKeyPressLogin} data={scanCode} />
                                                            {showInvalidQR && <small className="text-danger">*Invalid QR/Barcode!</small>}
                                                            <p className="text-center m-0">OR</p>
                                                        </>
                                                    }

                                                    <div>
                                                        <label htmlFor="userName">Email</label>
                                                        <Field onKeyUp={onCredentialsChange} name="userName" type="email" className="form-control" autocomplete={scanner === "true" && "off"} />
                                                        {errors.userName && touched.userName && <small className="text-danger">{errors.userName}</small>}
                                                    </div>

                                                    <div className="my-2">
                                                        <label htmlFor="password">Password</label>
                                                        <Field onKeyUp={onCredentialsChange} name="password" type="password" className="form-control" />
                                                        {errors.password && touched.password && (<small className="text-danger">{errors.password}</small>)}

                                                    </div>

                                                    <div className="text-center my-4">
                                                        <button className="btn btn-success px-5" type="submit">
                                                            {showSpinner ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Login"}
                                                        </button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                    : <>
                                        <div className="d-flex justify-content-center align-items-center vh-100">
                                            <div className="spinner-border" role="status">
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                        {
                            isClientValidated ?
                                <div className="version-warp my-2">
                                    V{clientDetails?.appVersion}
                                </div> : <></>
                        }

                    </div>
            }

        </section>
    )
};

export default Login;


